var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"main"},[_c('ul',{staticClass:"panel"},[_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.queryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("查询次数")])]),_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.currentQueryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("当前已被您扫")])])])]),_c('BaseNav',{attrs:{"indexs":[
      {
        index: 2,
        tracking: { userVisit: '42', sourceFlag: '7' }
      },
      {
        index: 3,
        tracking: { userVisit: '42', sourceFlag: '8' }
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }