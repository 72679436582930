<template>
  <div class="container">
    <div class="main">
      <ul class="panel">
        <li>
          <p class="p1">
            <span class="p2">{{ queryNum }}</span
            >次
          </p>
          <p class="p1">查询次数</p>
        </li>
        <li>
          <p class="p1">
            <span class="p2">{{ currentQueryNum }}</span
            >次
          </p>
          <p class="p1">当前已被您扫</p>
        </li>
      </ul>
    </div>
    <BaseNav
      :indexs="[
        {
          index: 2,
          tracking: { userVisit: '42', sourceFlag: '7' }
        },
        {
          index: 3,
          tracking: { userVisit: '42', sourceFlag: '8' }
        }
      ]"
    />
  </div>
</template>

<script>
import preventBack from '@/mixins/prevent-browser-back'

import {
  selectCountOfScanQrcodeByQrcode,
  selectCountOfScanQrcodeByQrcodeAndOpenId
} from '@/api/index.js'

export default {
  name: 'ActivityEnd',
  mixins: [preventBack],
  data() {
    return {
      queryNum: '',
      currentQueryNum: ''
    }
  },
  methods: {
    //查询次数（活动结束）
    getSelectCountOfScanQrcodeByQrcode() {
      selectCountOfScanQrcodeByQrcode().then(({ data }) => {
        this.queryNum = data
      })
    },
    //当前已被您扫(活动已结束)
    getSelectCountOfScanQrcodeByQrcodeAndOpenId() {
      selectCountOfScanQrcodeByQrcodeAndOpenId().then(({ data }) => {
        this.currentQueryNum = data
      })
    }
  },
  created() {
    this.getSelectCountOfScanQrcodeByQrcode()
    this.getSelectCountOfScanQrcodeByQrcodeAndOpenId()
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-bottom: 100px;
  height: 3117px;
  background: url('../../assets/activity-end.png') no-repeat center top / 100%
    auto;
  .panel {
    position: absolute;
    right: 80px;
    top: 684px;
    display: flex;
    justify-content: center;
    width: 500px;
    li {
      position: relative;
      flex: 1 1 auto;
      text-align: center;
      .p1 {
        font-size: 24px;
        color: #333;
      }
      .p2 {
        font-size: 52px;
        color: #e7471f;
      }
      &:after {
        content: ' ';
        position: absolute;
        top: 12px;
        right: 0;
        display: block;
        border-right: 2px dashed #bcac91;
        height: 78px;
      }
    }
    li:last-child {
      &:after {
        display: none;
      }
    }
  }
}
</style>
